<template>
  <div id="app">
    <RecordingButton />
  </div>
</template>

<script>
import RecordingButton from "./components/RecordingButton.vue";

export default {
  name: "App",
  components: {
    RecordingButton,
  },
};
</script>

<style>
/* Global styles for your app */
</style>
