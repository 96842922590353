<template>
  <div class="flex flex-col items-center min-h-screen p-20">
    <button
      @click="toggleRecording"
      class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-4 px-8 rounded"
    >
      {{ recording ? "Stop" : "Talk" }}
    </button>
    <!-- Audio element to play the fetched audio -->
    <audio class="p-20" v-if="audioURL" ref="audioPlayer" controls></audio>
    <p v-if="statusMessage" class="mt-4">{{ statusMessage }}</p>
    <div
      v-if="serverResponse"
      class="mt-4 p-2 border border-gray-300 rounded w-full h-full"
      readonly
    >
      {{ serverResponse }}
    </div>
  </div>
</template>

<script>
export default {
  name: "RecordingButton",
  data() {
    return {
      mediaRecorder: null,
      audioChunks: [],
      recording: false,
      statusMessage: "",
      serverResponse: "",
      audioURL: null,
    };
  },
  methods: {
    async getAudioData(audio) {
      const audioData = await audio.arrayBuffer();
      return audioData;
    },
    async toggleRecording() {
      // Toggle the recording state
      this.recording = !this.recording;

      // If currently recording, stop the recording
      if (this.mediaRecorder && this.mediaRecorder.state === "recording") {
        this.mediaRecorder.stop();
        this.statusMessage = "Your Coaching team has been notified...";
        this.mediaRecorder = null; // Reset the mediaRecorder
      } else {
        // Prepare for a new recording
        this.statusMessage = "Listening...";
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });

        // Initialize a new MediaRecorder instance
        this.mediaRecorder = new MediaRecorder(stream);

        // Set up event handlers
        this.mediaRecorder.ondataavailable = (event) => {
          this.audioChunks.push(event.data);
        };

        this.mediaRecorder.onstop = async () => {
          // Process the recorded data
          const audioBlob = new Blob(this.audioChunks);
          const formData = new FormData();
          formData.append("audio", audioBlob);

          // Send the data to the server
          fetch("https://ktalk-staging.rich-f2f.workers.dev", {
            method: "POST",
            body: formData,
          })
            .then((response) => response.json())
            .then((data) => {
              if (data.audio) {
                const audioBase64 = data.audio;
                this.audioURL = `data:audio/mp3;base64,${audioBase64}`;
                this.$nextTick(() => {
                  this.$refs.audioPlayer.src = this.audioURL; // Set the source of the audio element
                  this.$refs.audioPlayer.play(); // Play the audio
                });
              }
              this.serverResponse = data.coachResponse;
              this.statusMessage = "";
            });

          // Reset the chunks array for the next recording
          this.audioChunks = [];
        };

        // Start recording
        this.mediaRecorder.start();
      }
    },
  },
};
</script>

<style></style>
